export const config = {
  apiKey: "AIzaSyD-PHtrmiPBmo6CmNH6HuRxmPYGXIAMxeM",
  authDomain: "amicidellanaturaonline-c052e.firebaseapp.com",
  databaseURL:
    "https://amicidellanaturaonline-c052e-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "amicidellanaturaonline-c052e",
  storageBucket: "amicidellanaturaonline-c052e.appspot.com",
  messagingSenderId: "288715171472",
  appId: "1:288715171472:web:338403dd767a04a1f49a15",
  measurementId: "G-FB590VF587",
};
