import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { AppContext } from "../App";
import Container from "@material-ui/core/Container";
import Footer from "./Footer";

import signInBg from "../resources/backgrounds/signin_bg.png";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";

const PaperTextField = (props) => {
  return (
    <Paper style={{ margin: "10px" }}>
      <TextField {...props} />
    </Paper>
  );
};

const SignIn = ({ next }) => {
  const {
    name,
    setName,
    surname,
    setSurname,
    school,
    setSchool,
    email,
    setEmail,
  } = useContext(AppContext);

  const [error, setError] = useState("");

  return (
    <Box
      style={{
        backgroundImage: `url(${signInBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          flexDirection="column"
          minHeight="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="100%">
            <PaperTextField
              variant="outlined"
              required
              fullWidth
              id="name"
              label="Nome"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <PaperTextField
              variant="outlined"
              // margin="normal"
              required
              fullWidth
              id="surname"
              label="Cognome"
              autoFocus
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
            <PaperTextField
              variant="outlined"
              // margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Paper style={{ margin: "10px" }}>
              <FormControl fullWidth>
                <InputLabel
                  required
                  id="label-school"
                  style={{ marginLeft: "12px" }}
                >
                  Fascia d'età
                </InputLabel>
                <Select
                  labelId="label-school"
                  fullWidth
                  variant="outlined"
                  id="school-category"
                  // @ts-ignore
                  label="Fascia d'età"
                  required
                  value={school}
                  onChange={(event) => setSchool(event.target.value)}
                >
                  <MenuItem value={"materne"}>Materne</MenuItem>
                  <MenuItem value={"elementari"}>Elementari</MenuItem>
                  <MenuItem value={"medie"}>Medie</MenuItem>
                </Select>
              </FormControl>
            </Paper>
            <Typography
              color="error"
              gutterBottom
              style={{ marginTop: "15px" }}
            >
              {error}
            </Typography>
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              INIZIA ORA
            </Button>
          </Box>
        </Box>
        <Footer/>
      </Container>
    </Box>
  );

  function handleSubmit() {
    if (name && surname && school) {
      localStorage.setItem("Nome", name);
      localStorage.setItem("Cognome", surname);
      localStorage.setItem("Email", email);
      localStorage.setItem("Fascia D'età", school);
      next();
    } else {
      setError("Compilare tutti i campi obbligatori");
    }
  }
};

export default SignIn;
