import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Box,
    Link
  } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    policy: {
        padding: "10px",
        color: "white",
        fontSize: "11px"
    },
    footer: {
        textAlign: "center",
        borderRadius: "20px",
        padding: "10px",
        backgroundColor: "black",
        color: "white"
    }
  }));


  export default function Footer() {
    const classes = useStyles();
    return (
            <Box className={classes.footer}>
                <Link href="/privacy-policy" className={classes.policy}>
                        Privacy Policy
                </Link>
                <Link href="https://firebasestorage.googleapis.com/v0/b/amicidellanaturaonline-c052e.appspot.com/o/INFORMATIVA-amicidellanaturaonline.pdf?alt=media&token=a4e1ed05-d050-4b70-9d74-b1f41c311def" target="_blank" className={classes.policy}>
                        Trattamento dati Personali
                </Link>
                <Link href="/cookies-policy" className={classes.policy}>
                        Cookies Policy
                </Link>
            </Box>
          );
    }