import { IconButton, Typography } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

export function DialogTitle({ children, onClose }) {
  return (
    <MuiDialogTitle disableTypography>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: "20px", padding: "0.5em" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}
