import {
  Box,
  ButtonBase,
  Link,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { AppContext } from "App";
import React, { useContext, useState } from "react";
import resultBg from "../resources/backgrounds/result.png";
import basicBg from "../resources/backgrounds/questions/basic_bg.png";
import result1MobileBg from "../resources/backgrounds/result_1_mobile.png";
import result2MobileBg from "../resources/backgrounds/result_2_mobile.png";
import RewardModal from "./RewardModal";
import Footer from "./Footer";

const Results = () => {
  const { score, name } = useContext(AppContext);
  const result = Math.round((score * 100) / 7);
  const mobile = useMediaQuery("(max-aspect-ratio: 2/3)");
  const [showReward, setShowReward] = useState(false);

  const showRewardModal = () => setShowReward(true);
  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        backgroundImage: mobile
          ? `url(${result1MobileBg}), url(${result2MobileBg}), url(${basicBg})`
          : `url(${resultBg})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "inline-flex",
        overflowY: "auto",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        style={{
          flex: 1,
          flexShrink: 0,
          flexBasis: 0,
          minHeight: 0,
          margin: "20px",
          marginTop: "3%",
          maxWidth: "100vh",
          width: "90%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontSize: "max(min(4vw, 30px), 24px)" }}>
          👇 Risultati: 👇
        </Typography>
      </Paper>
      <Paper
        style={{
          flex: 1,
          flexShrink: 0,
          flexBasis: 0,
          minHeight: 0,
          margin: "20px",
          maxWidth: "100vw",
          width: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: ".5em",
        }}
      >
        <Typography
          style={{ fontSize: "max(min(4vw, 30px), 30px)" }}
          noWrap
          variant="h3"
        >
          Complimenti {name},
        </Typography>
        <Typography
          style={{ fontSize: "max(min(4vw, 30px), 30px)" }}
          noWrap
          variant="h3"
        >
          Il tuo punteggio è:
        </Typography>
        <Typography
          style={{ fontSize: "max(min(4vw, 30px), 30px)" }}
          noWrap
          variant="h3"
        >
          🥳 {result}% 🎉
        </Typography>
      </Paper>
      <Paper
        style={{
          flexGrow: 1,
          flexShrink: 0,
          flexBasis: 0,
          minHeight: 0,
          margin: "20px",
          maxWidth: "100vw",
          width: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Integrate Modal onClick ButtonBase */}

        <ButtonBase
          style={{
            width: "100%",
            height: "100%",
            fontSize: "max(min(4vw, 42px), 36px)",
            borderRadius: "20px",
            padding: ".5em",
          }}
          onClick={showRewardModal}
        >
          <Typography variant="h4">
            Solo per le <b>SCUOLE</b>: Prenota il tuo premio 🏆
          </Typography>
        </ButtonBase>
      </Paper>
      <Box
        flex={1}
        flexShrink={0}
        flexBasis={0}
        minHeight={0}
        width="100%"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <Paper
          style={{
            flex: 1,
            margin: "20px",
            maxHeight: "200px",
            maxWidth: "600px",
            height: "100%",
            textAlign: "left",
          }}
        >
          <Box
            style={{
              flex: 1,
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
            }}
          >
            <Typography
              component="h4"
              variant="h4"
              align="left"
              color="textPrimary"
              gutterBottom
            >
              Link aggiuntivi 🔍
            </Typography>

            <Link
              href="https://www.comune.cremona.it/node/500897"
              target="_blank"
            >
              https://www.comune.cremona.it/node/500897
            </Link>
            <Link
              href="https://www.comune.cremona.it/node/500899"
              target="_blank"
            >
              https://www.comune.cremona.it/node/500899
            </Link>
            <Link
              href="https://www.comune.cremona.it/node/500900"
              target="_blank"
            >
              https://www.comune.cremona.it/node/500900
            </Link>
            <Link
              href="https://www.comune.cremona.it/parco-po-morbasco"
              target="_blank"
            >
              https://www.comune.cremona.it/parco-po-morbasco
            </Link>
            <Link
              href="https://www.facebook.com/parchinaturacremona/"
              target="_blank"
            >
              https://www.facebook.com/parchinaturacremona/
            </Link>
          </Box>
        </Paper>
        <Paper
          style={{
            flex: 1,
            margin: "20px",
            maxHeight: "200px",
            maxWidth: "600px",
            height: "100%",
          }}
        >
          <Box
            style={{
              flex: 1,
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              component="h4"
              variant="h4"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Schede botaniche 📑
            </Typography>

            <Link
              href="https://www.comune.cremona.it/node/500898"
              target="_blank"
            >
              https://www.comune.cremona.it/node/500898
            </Link>
          </Box>
        </Paper>
        <Footer/>
      </Box>
      <RewardModal open={showReward} onClose={() => setShowReward(false)} />
    </Box>
  );
};

export default Results;
