import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import firebase from "firebase/app";
import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Redirect } from "react-router-dom";

const Login = () => {
  const [user, loading, error] = useAuthState(firebase.auth());
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  function login(e) {
    e.preventDefault();
    setLoginError("");
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(() => setLoginError("Credenziali non corrette."));
  }

  if (loading)
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  if (user) return <Redirect to="/dashboard" />;

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      margin="auto"
    >
      <form onSubmit={login}>
        <Box
          border="1px solid #aaa"
          padding="30px"
          height="300px"
          borderRadius="30px"
          textAlign="center"
        >
          <Typography variant="h4" style={{ textAlign: "left" }}>
            Login:
          </Typography>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            name="email"
            label="Email"
            margin="normal"
            fullWidth
          />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            label="Password"
            type="password"
            name="password"
            margin="normal"
            fullWidth
          />
          <Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            style={{ marginTop: "10px", marginBottom: "20px" }}
            type="submit"
          >
            Login
          </Button>
          <Typography color="error" variant="h6">
            {error || loginError}
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default Login;
