import React, { useState } from "react";
import { Questions } from "./Questions";
import Results from "./Results";
import SignIn from "./SignIn";
import Welcome from "./Welcome";

function MultiStepForm() {
  const [count, setCount] = useState(0);
  const next = () => setCount(count + 1);

  switch (count) {
    case 0:
      return <Welcome next={next} />;
    case 1:
      return <SignIn next={next} />;
    case 2:
      return <Questions next={next} />;
    default:
      return <Results />;
  }
}

export default MultiStepForm;
