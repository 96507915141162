import React from "react";
import ReactDOM from "react-dom";
import "./App.css";

import App from "./App";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";

import { config } from "./config";
console.log("Firebase Init");
firebase.initializeApp(config);

ReactDOM.render(<App />, document.getElementById("root"));
