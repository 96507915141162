import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  TextField,
} from "@material-ui/core";
import firebase from "firebase/app";
import React, { useState } from "react";
import { DialogTitle } from "./DialogTitle";

function RewardModalForm({ onSuccess, onClose }) {
  const sendEmail = firebase
    .app()
    .functions("europe-west3")
    .httpsCallable("mailServerEurope");
  const [loading, setLoading] = useState(false);
  return (
    <>
      <DialogTitle onClose={onClose}>
        Compila per ritirare il tuo premio:
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gridGap="20px">
            <TextField
              name="school"
              placeholder="Scuola"
              type="text"
              label="Scuola"
              required
            />
            <TextField
              name="ref"
              placeholder="Nome referente"
              type="text"
              label="Nome referente"
              required
            />
            <TextField
              name="city"
              placeholder="Città"
              type="text"
              label="Città"
              required
            />
            <TextField
              name="address"
              placeholder="Via"
              type="text"
              label="Via"
              required
            />
            <TextField
              name="cap"
              placeholder="CAP"
              type="text"
              label="CAP"
              inputProps={{ pattern: "[0-9]+" }}
              required
            />
            <TextField
              name="nStudents"
              placeholder="Numero studenti"
              type="number"
              label="Numero studenti"
              required
            />
            <TextField
              name="schoolClass"
              placeholder="Classe"
              type="text"
              label="Classe"
              required
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            style={{ margin: "20px 0 20px 0" }}
          >
            {loading ? <CircularProgress size="24px" /> : "Invia"}
          </Button>
        </form>
      </DialogContent>
    </>
  );

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    const data = {};

    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }

    firebase
      .firestore()
      .collection("/rewards")
      .add(data)
      .then(() => sendEmail(data))
      .then(onSuccess);
  }
}

export default RewardModalForm;
