import { Box, Button, CircularProgress } from "@material-ui/core";
import firebase from "firebase/app";
import React from "react";
import { CSVLink } from "react-csv";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { itIT } from '@material-ui/core/locale';

const theme = createMuiTheme(
  itIT,
);

const columns = [
  { flex: 1, field: "school", headerName: "Scuola" },
  { flex: 1, field: "ref", headerName: "Referente" },
  { flex: 1, field: "city", headerName: "Città" },
  { flex: 1, field: "address", headerName: "Indirizzo" },
  { flex: 1, field: "cap", headerName: "Cap" },
  {
    width: 150,
    field: "nStudents",
    headerName: "N. Studenti",
    type: "number",
  },
  { flex: 1, field: "schoolClass", headerName: "Classe" },

];

function RewardList() {
  const [vals, loading] = useCollectionData(
    firebase.firestore().collection("/rewards"),
    {
      idField: "id",
    }
  );

  return (
    <div>
      <Box height="600px">
        {!loading && vals && (
          <ThemeProvider theme={theme}>        
            <DataGrid
              rows={vals}
              columns={columns}
              pageSize={50}
              disableColumnFilter
            />
          </ThemeProvider>
        )}
        {loading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <CSVLink
          data={loading ? [] : vals}
          style={{ textDecoration: "none", marginTop: "10px" }}
          filename={`dati.csv`}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
          >
            Scarica dati
          </Button>
        </CSVLink>
      </Box>
    </div>
  );
}

export default RewardList;
