import {
  Button,
  Container,
  createMuiTheme,
  ThemeProvider,
  Link,
  Box,
} from "@material-ui/core";
import Dashboard from "components/Dashboard";
import Login from "components/Login";
import "firebase/auth";
import "firebase/firestore";
import "fontsource-roboto";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import MultiStepForm from "./components/MultiStepForm";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';

export const AppContext = React.createContext({
  name: "",
  surname: "",
  email: "",
  school: "",
  score: 0,
  setName: (name) => {},
  setSurname: (surname) => {},
  setSchool: (school) => {},
  setEmail: (email) => {},
  setScore: (score) => {},
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#236600",
    },
    secondary: {
      main: "#FF5033",
    },
    background: {
      paper: "rgba(255, 255, 255, 1)",
    },
  },
  shape: {
    borderRadius: 20,
  },
});

function App() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [school, setSchool] = useState("");
  const [score, setScore] = useState(0);

  return (
    <AppContext.Provider
      value={{
        name,
        surname,
        email,
        school,
        score,
        setName,
        setSurname,
        setEmail,
        setSchool,
        setScore,
      }}
    >
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/">
              <MultiStepForm />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/privacy-policy">
              <IubendaContent link="https://www.iubenda.com/api/privacy-policy/12585381" />
            </Route>
            <Route path="/cookies-policy">
              <IubendaContent link="https://www.iubenda.com/api/privacy-policy/12585381/cookie-policy" />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

function IubendaContent({ link }) {
  const [content, setContent] = useState("");
  useEffect(() => {
    fetch(link)
      .then((res) => res.json())
      .then((res) => res.content)
      .then(setContent);
  }, [link]);
  return (
    <Container maxWidth="md" style={{paddingTop: "4em"}}>
      <Box>
      <Link href="/">
          <Button color="primary" variant="contained" startIcon={<ArrowBackIcon />}>
            Torna indietro
          </Button>
        </Link>
      </Box>
      <Box style={{float: "right"}}>
        <a className="iubenda-cs-preferences-link">
          <Button color="primary" variant="contained" endIcon={<EditAttributesIcon />}>
            Preferenze Cookies
          </Button>
        </a>
      </Box>

      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </Container>
  );
}

export default App;
