import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { DialogTitle } from "./DialogTitle";
import RewardModalForm from "./RewardModalForm";

function RewardModal({ open, onClose }) {
  const [success, setSuccess] = useState(false);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {!success ? (
        <RewardModalForm onSuccess={() => setSuccess(true)} onClose={onClose} />
      ) : (
        <>
          <DialogTitle onClose={onClose}>
            Grazie per aver partecipato!
          </DialogTitle>
          <DialogContent>
            <Box
              textAlign="center"
              display="flex"
              flexDirection="column"
              gridGap={5}
            >
              <Typography
                style={{ marginBottom: "20px", padding: "0.5em" }}
                variant="h4"
              >
                Rendiamo il pianeta un posto migliore
              </Typography>
              <Typography
                style={{ marginBottom: "20px", padding: "0.5em" }}
                variant="h2"
              >
                🌍
              </Typography>
              <Button
                color="primary"
                variant="contained"
                style={{ marginBottom: "20px" }}
                onClick={() => window.location.reload()}
              >
                Gioca Ancora
              </Button>
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default RewardModal;
