import { useMediaQuery } from "@material-ui/core";
import { AppContext } from "App";
import React, { useContext } from "react";
import questionElementari from "../resources/elementari/questions.json";
import questionMaterne from "../resources/materne/questions.json";
import questionMedie from "../resources/medie/questions.json";
import frameElementari from "../resources/backgrounds/questions/elementari_bg.png";
import frameMedie from "../resources/backgrounds/questions/medie_bg.png";
import frameMaterne from "../resources/backgrounds/questions/materne_bg.png";
import frameMobileElementari from "../resources/backgrounds/questions/elementari_bg_mobile.png";
import frameMobileMedie from "../resources/backgrounds/questions/medie_bg_mobile.png";
import frameMobileMaterne from "../resources/backgrounds/questions/materne_bg_mobile.png";
import { QuestionJson } from "./QuestionJson";

export const Questions = ({ next }) => {
  const { school } = useContext(AppContext);
  const mobile = useMediaQuery("(max-aspect-ratio: 2/3)");

  switch (school) {
    case "materne":
      return (
        <QuestionJson
          next={next}
          questions={questionMaterne}
          font="Comic Sans MS"
          frame={mobile ? frameMobileMaterne : frameMaterne}
        />
      );
    case "elementari":
      return (
        <QuestionJson
          next={next}
          questions={questionElementari}
          font="Arial"
          frame={mobile ? frameMobileElementari : frameElementari}
        />
      );
    case "medie":
      return (
        <QuestionJson
          next={next}
          questions={questionMedie}
          font="Roboto"
          frame={mobile ? frameMobileMedie : frameMedie}
        />
      );

    default:
      return null;
  }
};
