import {
  AppBar,
  Box,
  Button,
  Container,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@material-ui/core";
import firebase from "firebase/app";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import RewardList from "./dashboard/Rewards";
import UserList from "./dashboard/Users";

const Dashboard = () => {
  const history = useHistory();
  const [selected, setSelected] = useState(0);

  function logout() {
    firebase
      .auth()
      .signOut()
      .then(() => history.push("/login"));
  }
  return (
    <Box height="100%">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Dashboard:
          </Typography>
          <Button color="inherit" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
        <Tabs value={selected} onChange={(_e, value) => setSelected(value)}>
          <Tab label="Utenti" />
          <Tab label="Premi" />
        </Tabs>
      </AppBar>
      <TabPanel value={selected} index={0}>
        <UserList />
      </TabPanel>
      <TabPanel value={selected} index={1}>
        <RewardList />
      </TabPanel>
    </Box>
  );
};

export default Dashboard;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Container style={{ paddingTop: "10px" }}>{children}</Container>
      )}
    </div>
  );
}
