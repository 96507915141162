import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "./Footer";

import welcomeImageBg from "../resources/backgrounds/welcome_bg.png";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${welcomeImageBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  block: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  playButton: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Welcome = ({ next }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          flexDirection="column"
          minHeight="100vh"
          className={classes.block}
        >
          <Typography component="h1" variant="h2" style={{ fontWeight: 500 }}>
            BENVENUTO
          </Typography>
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.playButton}
            onClick={next}
          >
            GIOCA ORA
          </Button>
        </Box>
        <Footer/>
      </Container>
    </div>
  );
};

export default Welcome;
