import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import { AppContext } from "App";
import firebase from "firebase/app";
import React, { useContext, useEffect, useState } from "react";
// @ts-ignore
import basicBg from "../resources/backgrounds/questions/basic_bg.png";
import Footer from "./Footer";

export const QuestionJson = ({ questions, next, frame, font }) => {
  const { score, name, surname, email, school, setScore } = useContext(
    AppContext
  );
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState([true, true]);

  useEffect(() => {
    setLoading([true, true]);
  }, [count]);

  useEffect(() => {
    if (count >= questions.length) {
      firebase.database().ref("/").push({
        score,
        name,
        surname,
        email,
        school,
        date: firebase.database.ServerValue.TIMESTAMP,
      });
      next();
    }
  });
  return (
    <Box
      style={{
        backgroundImage: `url(${frame}), url(${basicBg})`,
        backgroundSize: "cover, cover",
        backgroundPosition: "bottom, center",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        fontFamily: font,
      }}
    >
      <Box
        display="flex"
        height="100vh"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Paper style={{ padding: "10px", margin: "10px" }}>
          <Typography
            style={{
              fontSize: "max(min(4vw, 30px), 20px)",
              fontFamily: "inherit",
            }}
            gutterBottom
          >
            {questions[count]?.question}
          </Typography>
        </Paper>
        <Paper
          style={{
            padding: "10px",
            margin: "10px",
            minHeight: "20vh",
            display: "flex",
            width: "80%",
            maxHeight: "30vh",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          {questions[count]?.images?.map((image, i) => (
            <Box flex="1" maxHeight="100%" textAlign="center" key={i}>
              <img
                alt="question"
                src={image}
                onLoad={() => {
                  const newArr = [...loading];
                  newArr[i] = false;
                  setLoading(newArr);
                }}
                style={{
                  objectFit: "contain",
                  maxWidth: "100%",
                  maxHeight: "29vh",
                  margin: "auto",
                  display: loading[i] ? "none" : "block",
                }}
              />
              {loading[i] && (
                <Box flex={1} width="100%">
                  <CircularProgress />
                </Box>
              )}
            </Box>
          ))}
        </Paper>
        <Box display="flex" flexDirection="column" flexGrow="1" maxHeight="20%">
          {questions[count]?.answers.map((a, i) => (
            <Button
              variant="contained"
              onClick={() => {
                if (i === questions[count].correct) {
                  setScore(score + 1);
                }
                setCount(count + 1);
              }}
              style={{
                flexGrow: 1,
                margin: "10px",
                fontSize: "min(4vw, 20px)",
                fontFamily: "inherit",
              }}
              key={i}
            >
              {a}
            </Button>
          ))}
        </Box>
        <Box
          style={{
            marginTop: "100px",
            padding: "10px",
            backgroundColor: "#fff",
            borderRadius: "20px",
            alignSelf: "flex-end",
          }}
        >
          <Typography gutterBottom style={{ fontFamily: "inherit" }}>
            Percorso: {school}
          </Typography>
        </Box>
      </Box>
      <Footer/>
    </Box>
  );
};
