import { Box, Button, CircularProgress } from "@material-ui/core";
import firebase from "firebase/app";
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
import { useListVals } from "react-firebase-hooks/database";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { itIT } from '@material-ui/core/locale';

const theme = createMuiTheme(
  itIT,
);


const columns = [
  { flex: 1, field: "name", headerName: "Nome" },
  { flex: 1, field: "surname", headerName: "Cognome" },
  { flex: 1, field: "email", headerName: "Email" },
  { flex: 1, field: "school", headerName: "Scuola" },
  { width: 120, field: "score", headerName: "Punteggio", type: "number" },
  { flex: 1, field: "date", type: "date", headerName: "Data" },
];

function UserList() {
  const [vals, loading] = useListVals(firebase.database().ref("/"), {
    keyField: "id",
  });

  const myData = vals.map((r) => {
    const date = new Date(r.date);
    date.setHours(0, 0, 0, 0);
    return { ...r, date };
  });

  return (
    <div>
      <Box height="600px">
        {!loading && vals && (
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={myData}
              columns={columns}
              pageSize={50}
              disableColumnFilter
            />
          </ThemeProvider>
        )}
        {loading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box style={{ marginTop: "10px" }}>
        <CSVLink
          data={myData.map((r) => ({
            name: r.name,
            surname: r.surname,
            school: r.school,
            email: r.email,
            score: r.score,
            date: moment(r.date).format("DD/MM/YYYY"),
          }))}
          style={{ textDecoration: "none", marginTop: "10px" }}
          filename={`dati.csv`}
        >
          <Button variant="contained" color="primary" size="large">
            Scarica dati
          </Button>
        </CSVLink>
      </Box>
    </div>
  );
}

export default UserList;
